import './footer.css';
const Footer = () => {
    return (
        <div className='footer'>
            <p className='text-footer'>
                &copy; All rights reserved Natalia Byrdy
            </p>
        </div>
    )
};

export default Footer;